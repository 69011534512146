<template>
  <div class="main">
    <heads></heads>
    <ctdkbanners></ctdkbanners>
    <div class="cons">
      <div class="xqmain">
        <div class="xqmain-maintitle">
          <div>Recommended results</div>
          <div>
            <!-- <el-button type="primary" @click="comeback">返回上级</el-button> -->
          </div>
        </div>

        <div class="ls-title">
          <div class="ls-title-zy"><span>Title:</span>{{ biaot }}</div>
          <div :class="iszhankai == true ? zhaiyaoclasszk : zhaiyaoclass">
            <span>Abstract:</span>{{ zhaiy }}
            <div class="ls-title-zhank" @click="iszhankai = !iszhankai">
              {{ iszhankai == true ? "Collapse" : "unfold" }}
            </div>
          </div>
          <div class="ls-title-zy"><span>Keywords:</span>{{ guanjianci }}</div>
        </div>

        <div class="tips">Find a total of {{ qikanlistshow.length }} related journals for you</div>

        <div class="allqk">
          <el-table :data="qikanlistshow" border  style="width: 100%">
            <el-table-column type="index" width="50" />
            <el-table-column prop="qkname" label="Name of the journal" />
            <!-- <el-table-column prop="re" label="Recommended values" sortable  width="250" /> -->
          </el-table>
        </div>
      </div>
    </div>

    <footers></footers>
  </div>
</template>

<script setup>
import { reactive, ref, getCurrentInstance } from "vue";
import { get, post } from "@/utils/index";
import { useRouter } from "vue-router";
const router = useRouter();
const { proxy } = getCurrentInstance();

let qikanlist = reactive([]);
// 需要显示的期刊
let qikanlistshow = reactive([]);

let biaot = ref("");
let zhaiy = ref("");
let guanjianci = ref("");
// 展开收缩
let iszhankai = ref(false);
let zhaiyaoclass = ref("ls-title-zy ls-title-zynr");
let zhaiyaoclasszk = ref("ls-title-zy ls-title-zynr-zk");

// 获取投稿发现
const gettgfx = () => {
  get("/en/journalRecommend/GetBynid", {
    id: router.currentRoute.value.query.id,
  }).then((res) => {
    // 基本属性
    biaot.value = res.data.btword;
    zhaiy.value = res.data.zyword;
    guanjianci.value = res.data.kwword;

    qikanlist.length = 0;
    qikanlist.push(...JSON.parse(res.data.xgqk));
    qikanlistshow.length = 0;
    qikanlist.forEach(e=>{
      e.re=(e.re).toFixed(3)
      qikanlistshow.push(e);
    })

  });
};
gettgfx();

</script>

<style scoped>
.main {
  background: #fff;
}
.cons {
  width: 1200px;
  margin: auto;
  padding: 30px 0 50px 0;
}
.xqmain-maintitle {
  border-bottom: 3px double #333;
  height: 50px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.allqk::after {
  content: "";
  display: table;
  clear: both;
}
.allqk-list-img {
  width: 98px;
  height: 128px;
  border: 1px solid #ccc;
}
.allqk-list-img:hover {
  border: 1px solid rgb(56, 125, 252);
}
.allqk-list {
  width: 100%;
  height: 40px;
}
.allqk-list-title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  text-align: center;
}

.ls-title {
  width: 1200px;
  margin: auto;
}

.ls-title-zy {
  position: relative;
  padding: 10px 15px;
  background: #f9f9f9;
  box-sizing: border-box;
  text-align: justify;
  line-height: 24px;
  margin-bottom: 5px;
  font-size: 14px;
}
.ls-title-zynr {
  height: 63px;
  padding: 10px 60px 10px 15px;
  overflow: hidden;
}
.ls-title-zynr-zk {
  height: auto;
  padding: 10px 60px 10px 15px;
  overflow: hidden;
}
.ls-title-bt {
  font-size: 28px;
  margin-bottom: 15px;
}
.ls-title-zy > span {
  font-weight: bold;
}
.ls-title-zy-i {
  font-style: normal;
}
.ls-title-zhank {
  position: absolute;
  right: 0;
  top: 5px;
  width: 60px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #409eff;
  cursor: pointer;
  font-size: 14px;
}
.tips {
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #999;
}
</style>
